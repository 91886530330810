/* --------------- variables --------------------- */

/* Break Point */
$breakpoint_sp		:980px;
$breakpoint_tb		:980px;
$breakpoint_pc		:$breakpoint_sp + 1;


/* Color */
$c_base : #00a3a8;/*サイトカラー*/
$c_gray : #868686;/*サイトカラー*/
$c_text : #000; /*テキストに使うフォントカラー*/
$c_href : #000; /*テキスト内で使うリンクカラー*/

/* font */
@import url('https://fonts.googleapis.com/css2?family=Lato:wght@400;700&family=Noto+Sans+JP:wght@400;700;900&family=Oswald:wght@400;700&display=swap');

$ff_jp : 'Noto Sans JP', sans-serif;
$ff_en : 'Lato', sans-serif;
$ff_oswald : 'Oswald', sans-serif;