#contents{
    letter-spacing: 2px;
    @if $type == sp{
    }
    @if $type == pc{
    }
    
}
.common_inner{
    @if $type == sp{
        padding: 0 20px;
    }
    @if $type == pc{
        width: 96%;
        max-width: 1260px;
        margin: 0 auto;
        padding: 0 60px 0 0;
    }
}
#main{
    overflow: hidden;
    @if $type == sp{
        background: url(../images/home/main_bg_sp.png) no-repeat center top;
        background-size: 100% auto;
        position: relative;
        padding-top: 350px;
        margin-top: -65px;
    }
    @if $type == pc{
        background: url(../images/home/main_bg.png) no-repeat center top;
        background-size: 100% auto;
        padding-top: 170px;
        margin-top: -170px;
        padding-bottom: 170px;
        position: relative;
    }
    .catch{
        -ms-writing-mode: tb-rl;
        writing-mode: vertical-rl;
        color: #fff;
        @if $type == sp{
            position: absolute;
            top: 0;
            left: 0;
            width: 20px;
            height: 100%;
            background-color: $c_base;
            color: #fff;
            font-size: 11px;
            padding-top: 10px;
            letter-spacing: .1em;
            display: flex;
            align-items: center;
        }
        @if $type == pc{
            font-size: 18px;
            position: absolute;
            top: 90px;
            left: 60px;
            span{
                display: inline-block;
                padding: 7px 5px;
                background-color: $c_base;
                margin-left: 5px;
                letter-spacing: 0.1em;
            }
        }
    }
    .mv_catch{
        @if $type == sp{
            font-size: 15px;
            font-weight: 900;
            position: relative;
            z-index: 2;
            padding-left: 40px;
        }
        @if $type == pc{
            //font-size: 30px;
            font-size: 2.1vw;
            font-weight: 900;
            position: relative;
            z-index: 2;
            margin-top: 250px;
        }
        .c-text{
            display: inline-block;
            overflow: hidden;
            &:not(:first-of-type){
                margin-top: 10px;
                @if $type == sp{
                    margin-top: 5px;
                }
            }
            span{
                background-color: #fff;
                display: inline-block;
                padding: 5px;
            }
        }
        .slidein {
            transform: translateY(-100%);
            animation: slidein 0.8s ease forwards;
        }
        @keyframes slidein {
            100% {transform: translateY(0%);}
        }

        /*以下遅延の指定*/
        .txt01 {animation-delay: 1s;}
        .txt02 {animation-delay: 1.8s;}
        .txt03 {animation-delay: 2.6s;}
    }
    .logo{
        @if $type == sp{
            margin-top: 15px;
            padding-left: 40px;
            img{
                width: 94px;
            }
        }
        @if $type == pc{
            margin-top: 100px;
            padding-right: 20px;
        }
    }
    .mv_img{
        overflow: hidden;
        @if $type == sp{
            position: absolute;
            top: 70px;
            right: -70px;
            width: 375px;
            height: 375px;
            img{
                max-width: 100%;
            }
        }
        @if $type == pc{
            display: inline-block;
            position: absolute;
            right: calc((100% - 1200px) / 2);
            top: 150px;
            width: 835px;
            height: 836px;
            img{
                max-width: 100%;
            }
        }
        &.fadein {
            opacity: 0;
            animation: fadein 2s ease forwards;
        }
        @keyframes fadein {
            100% {  opacity: 1;}
        }
    }
}//main

#aspnote{
    @if $type == sp{
        margin-top: 35px;
    }
    @if $type == pc{
        margin-top: 120px;
    }
    .common_inner{
        @if $type == sp{
        }
        @if $type == pc{
            display: flex;
        }
    }
    h2{
        @if $type == sp{
            img{
                width: 207px;
            }
        }
        @if $type == pc{
            width: 278px;
        }
    }
    .note_list{
        @if $type == sp{
            margin-top: 25px;
        }
        @if $type == pc{
            width: calc(100% - 278px);
            padding-left: 50px;
        }
    }
}//aspnote

#online{
    text-align: center;
    img{
        max-width: 100%;
    }
    @if $type == sp{
        margin-top: 60px;
    }
    @if $type == pc{
        margin-top: 210px;
    }
    a{
        display: block;
        transition: 0.3s;
        &:hover{
            opacity: .7;
        }
    }
}//online

#aboutus{
    @if $type == sp{
        margin-top: 70px;
    }
    @if $type == pc{
        margin-top: 170px;
        position: relative;
        &:before{
            content: "";
            display: inline-block;
            background: url(../images/home/aboutus_kazari_1.png) no-repeat;
            width: 171px;
            height: 412px;
            position: absolute;
            top: -290px;
            left: 0;
            z-index: -1;
        }
        &:after{
            content: "";
            display: inline-block;
            background: url(../images/home/aboutus_kazari_2.png) no-repeat;
            width: 749px;
            height: 300px;
            position: absolute;
            right: 0px;
            bottom: 290px;
        }
    }
    .heading{
        color: #3b3b3b;
        display: flex;
        align-items: center;
        position: relative;
        font-weight: bold;
        line-height: 1;
        @if $type == sp{
            padding-left: 25px;
        }
        @if $type == pc{
            padding-left: 35px;
        }
        &:before{
            content: "";
            display: inline-block;
            background: url(../images/common/h2_kazari.png) no-repeat 0 0;
            background-size: cover;
            width: 26px;
            height: 26px;
            position: absolute;
            left: 0;
            top: -20px;
            @if $type == sp{
                width: 13px;
                height: 13px;
                top: -10px;
            }
        }
        .en_title{
            font-family: $ff_en;
            letter-spacing: 0.2em;
            @if $type == sp{
                font-size: 15px;
            }
            @if $type == pc{
                font-size: 26px;
            }
        }
        h2{
            position: relative;
            @if $type == sp{
                font-size: 12px;
                padding-left: 60px;
                margin-left: 5px;
            }
            @if $type == pc{
                font-size: 18px;
                padding-left: 75px;
                margin-left: 5px;
            }
            &:before{
                content: "";
                display: inline-block;
                height: 1px;
                width: 70px;
                background-color: #3b3b3b;
                position: absolute;
                top: 10px;
                left: 0;
                @if $type == sp{
                    width: 55px;
                    top: 6px;
                }
            }
        }
    }//heading
    #company{
        @if $type == sp{
            margin-top: 35px;
        }
        @if $type == pc{
            padding-top: 100px;
            display: flex;
        }
        figure{
            @if $type == sp{
            }
            @if $type == pc{
                width: calc(100% - 490px);
            }
            img{
                max-width: 100%;
            }
        }
        .text_wrap{
            @if $type == sp{
                margin-top: 35px;
            }
            @if $type == pc{
                width: 490px;
                padding-left: 70px;
                padding-top: 230px;
                position: relative;
                &:before{
                    content: "";
                    display: inline-block;
                    background: url(../images/home/company_img_2.png) no-repeat;
                    width: 343px;
                    height: 222px;
                    position: absolute;
                    right: 0;
                    top: -80px;
                }
            }
            h3{
                @if $type == sp{
                    font-size: 20px;
                }
                @if $type == pc{
                    font-size: 28px;
                }
            }
            p{
                line-height: 1.8;
                @if $type == sp{
                    margin-top: 10px;
                    font-size: 14px;
                }
                @if $type == pc{
                    margin-top: 20px;
                    font-size: 18px;
                }
            }
            .more_wrap{
                @if $type == sp{
                    text-align: right;
                }
            }
            .more{
                text-decoration: none;
                color: #000;
                display: inline-block;
                position: relative;
                font-weight: bold;
                font-family: $ff_en;
                @if $type == sp{
                    margin-top: 15px;
                    font-size: 14px;
                    padding-left: 65px;
                }
                @if $type == pc{
                    margin-top: 60px;
                    font-size: 19px;
                    padding-left: 90px;
                    &:hover{
                        &:before{
                            background: url(../images/common/arrow_w.png) no-repeat center $c_base;
                            border: 1px solid $c_base;
                        }
                    }
                }
                &:before{
                    content: "";
                    display: inline-block;
                    border: 1px solid #000;
                    border-radius: 50%;
                    position: absolute;
                    top: 0;
                    left: 0;
                    background: url(../images/common/arrow.png) no-repeat center;
                    @if $type == sp{
                        width: 45px;
                        height: 45px;
                        background-size: 8px;
                    }
                    @if $type == pc{
                        width: 70px;
                        height: 70px;
                        transition: .3s;
                    }
                }
                span{
                    display: inline-block;
                    background: url(../images/common/more_line.png) no-repeat left bottom;
                    @if $type == sp{
                        padding: 5px 0 20px;
                    }
                    @if $type == pc{
                        padding: 15px 0 30px;
                    }
                }
            }
        }
    }//company
    #printing{
        @if $type == sp{
            margin-top: 30px;
        }
        @if $type == pc{
            padding-top: 140px;
            display: flex;
            flex-direction: row-reverse;
        }
        figure{
            @if $type == sp{
                transform: translateX(-20px);
                img{
                    width: calc(100% + 20px);
                }
            }
            @if $type == pc{
                width: calc(100% - 490px);
                img{
                    max-width: 100%;
                }
            }
        }
        .text_wrap{
            @if $type == sp{
                margin-top: 35px;
            }
            @if $type == pc{
                width: 490px;
                padding-right: 70px;
                padding-top: 230px;
                position: relative;
                &:before{
                    content: "";
                    display: inline-block;
                    background: url(../images/home/printing_img_2.png) no-repeat;
                    width: 172px;
                    height: 121px;
                    position: absolute;
                    right: 55px;
                    top: 30px;
                }
            }
            h3{
                @if $type == sp{
                    font-size: 20px;
                }
                @if $type == pc{
                    font-size: 28px;
                }
            }
            p{
                line-height: 1.8;
                @if $type == sp{
                    margin-top: 10px;
                    font-size: 14px;
                }
                @if $type == pc{
                    margin-top: 20px;
                    font-size: 18px;
                }
            }
            .more_wrap{
                @if $type == sp{
                    text-align: right;
                }
            }
            .more{
                text-decoration: none;
                color: #000;
                display: inline-block;
                position: relative;
                font-weight: bold;
                font-family: $ff_en;
                @if $type == sp{
                    margin-top: 15px;
                    font-size: 14px;
                    padding-left: 65px;
                }
                @if $type == pc{
                    margin-top: 60px;
                    font-size: 19px;
                    padding-left: 90px;
                    &:hover{
                        &:before{
                            background: url(../images/common/arrow_w.png) no-repeat center $c_base;
                            border: 1px solid $c_base;
                        }
                    }
                }
                &:before{
                    content: "";
                    display: inline-block;
                    border: 1px solid #000;
                    border-radius: 50%;
                    position: absolute;
                    top: 0;
                    left: 0;
                    background: url(../images/common/arrow.png) no-repeat center;
                    @if $type == sp{
                        width: 45px;
                        height: 45px;
                        background-size: 8px;
                    }
                    @if $type == pc{
                        width: 70px;
                        height: 70px;
                        transition: .3s;
                    }
                }
                span{
                    display: inline-block;
                    background: url(../images/common/more_line.png) no-repeat left bottom;
                    @if $type == sp{
                        padding: 5px 0 20px;
                    }
                    @if $type == pc{
                        padding: 15px 0 30px;
                    }
                }
            }
        }
    }//printing
    #service{
        @if $type == sp{
            margin-top: 30px;
        }
        @if $type == pc{
            padding-top: 100px;
            display: flex;
        }
        figure{
            @if $type == sp{
                img{
                    width: calc(100% + 20px);
                }
            }
            @if $type == pc{
                width: calc(100% - 435px);
                img{
                    max-width: 100%;
                }
            }
        }
        .text_wrap{
            @if $type == sp{
                margin-top: 35px;
            }
            @if $type == pc{
                width: 435px;
                padding-left: 120px;
                padding-top: 150px;
            }
            h3{
                @if $type == sp{
                    font-size: 20px;
                }
                @if $type == pc{
                    font-size: 28px;
                }
            }
            p{
                line-height: 1.8;
                @if $type == sp{
                    margin-top: 10px;
                    font-size: 14px;
                }
                @if $type == pc{
                    margin-top: 20px;
                    font-size: 18px;
                }
            }
            .more_wrap{
                @if $type == sp{
                    text-align: right;
                }
            }
            .more{
                text-decoration: none;
                color: #000;
                display: inline-block;
                position: relative;
                font-weight: bold;
                font-family: $ff_en;
                @if $type == sp{
                    margin-top: 15px;
                    font-size: 14px;
                    padding-left: 65px;
                }
                @if $type == pc{
                    margin-top: 60px;
                    font-size: 19px;
                    padding-left: 90px;
                    &:hover{
                        &:before{
                            background: url(../images/common/arrow_w.png) no-repeat center $c_base;
                            border: 1px solid $c_base;
                        }
                    }
                }
                &:before{
                    content: "";
                    display: inline-block;
                    border: 1px solid #000;
                    border-radius: 50%;
                    position: absolute;
                    top: 0;
                    left: 0;
                    background: url(../images/common/arrow.png) no-repeat center;
                    @if $type == sp{
                        width: 45px;
                        height: 45px;
                        background-size: 8px;
                    }
                    @if $type == pc{
                        width: 70px;
                        height: 70px;
                        transition: .3s;
                    }
                }
                span{
                    display: inline-block;
                    background: url(../images/common/more_line.png) no-repeat left bottom;
                    @if $type == sp{
                        padding: 5px 0 20px;
                    }
                    @if $type == pc{
                        padding: 15px 0 30px;
                    }
                }
            }
        }
    }//service
}//aboutus

#works{
    @if $type == sp{
        margin-top: 70px;
        position: relative;
        &:before{
            content: "";
            display: inline-block;
            background: url(../images/home/works_kazari_sp.png) no-repeat;
            background-size: cover;
            width: 227px;
            height: 301px;
            position: absolute;
            right: 0;
            bottom: 140px;
        }
    }
    @if $type == pc{
        margin-top: 200px;
        position: relative;
        &:before{
            content: "";
            display: inline-block;
            background: url(../images/home/works_kazari.png) no-repeat;
            width: 751px;
            height: 602px;
            position: absolute;
            right: 0;
            top: 0;
        }
    }
    .heading{
        color: #3b3b3b;
        display: flex;
        align-items: center;
        position: relative;
        font-weight: bold;
        line-height: 1;
        @if $type == sp{
            padding-left: 25px;
        }
        @if $type == pc{
            padding-left: 35px;
        }
        &:before{
            content: "";
            display: inline-block;
            background: url(../images/common/h2_kazari.png) no-repeat 0 0;
            background-size: cover;
            width: 26px;
            height: 26px;
            position: absolute;
            left: 0;
            top: -20px;
            @if $type == sp{
                width: 13px;
                height: 13px;
                top: -10px;
            }
        }
        .en_title{
            font-family: $ff_en;
            letter-spacing: 0.2em;
            @if $type == sp{
                font-size: 15px;
            }
            @if $type == pc{
                font-size: 26px;
            }
        }
        h2{
            position: relative;
            @if $type == sp{
                font-size: 12px;
                padding-left: 60px;
                margin-left: 5px;
            }
            @if $type == pc{
                font-size: 18px;
                padding-left: 75px;
                margin-left: 5px;
            }
            &:before{
                content: "";
                display: inline-block;
                height: 1px;
                width: 70px;
                background-color: #3b3b3b;
                position: absolute;
                top: 10px;
                left: 0;
                @if $type == sp{
                    width: 55px;
                    top: 6px;
                }
            }
        }
    }//heading
    .works_wrap{
        @if $type == sp{
        }
        @if $type == pc{
            display: flex;
        }
        .more_wrap{
            text-align: right;
        }
        .more{
            text-decoration: none;
            color: #000;
            display: inline-block;
            position: relative;
            font-weight: bold;
            font-family: $ff_en;
            @if $type == sp{
                margin-top: 30px;
                font-size: 14px;
                padding-left: 65px;
            }
            @if $type == pc{
                margin-top: 60px;
                font-size: 19px;
                padding-left: 90px;
                &:hover{
                    &:before{
                        background: url(../images/common/arrow_w.png) no-repeat center $c_base;
                        border: 1px solid $c_base;
                    }
                }
            }
            &:before{
                content: "";
                display: inline-block;
                border: 1px solid #000;
                border-radius: 50%;
                position: absolute;
                top: 0;
                left: 0;
                background: url(../images/common/arrow.png) no-repeat center;
                @if $type == sp{
                    width: 45px;
                    height: 45px;
                    background-size: 8px;
                }
                @if $type == pc{
                    width: 70px;
                    height: 70px;
                    transition: .3s;
                }
            }
            span{
                display: inline-block;
                background: url(../images/common/more_line.png) no-repeat left bottom;
                @if $type == sp{
                    padding: 5px 0 20px;
                }
                @if $type == pc{
                    padding: 15px 0 30px;
                }
            }
        }
        .text_wrap{
            position: relative;
            @if $type == sp{
                margin-top: 35px;
            }
            @if $type == pc{
                width: 50%;
                padding-left: 80px;
                padding-top: 160px;
                padding-right: 130px;
            }
            &:before{
                content: "";
                display: inline-block;
                background: url(../images/home/h3_works_.png) no-repeat;
                background-size: cover;
                position: absolute;
                @if $type == sp{
                    width: 179px;
                    height: 57px;
                    top: -35px;
                    right: 0px;
                }
                @if $type == pc{
                    width: 357px;
                    height: 114px;
                    top: 50px;
                    left: 80px;
                }
            }
            &:after{
                content: "";
                display: inline-block;
                background: url(../images/home/works_shape.png) no-repeat;
                background-size: cover;
                position: absolute;
                width: 144px;
                height: 43px;
                right: 0;
                top: 140px;
                @if $type == sp{
                    width: 72px;
                    height: 22px;
                    top: 15px;
                    right: 60px;
                }
            }
            h3{
                border-left: 4px solid $c_base;
                line-height: 1.3;
                @if $type == sp{
                    font-size: 20px;
                    padding-left: 20px;
                }
                @if $type == pc{
                    font-size: 30px;
                    padding-left: 30px;
                }
            }
            p{
                line-height: 1.8;
                @if $type == sp{
                    margin-top: 20px;
                    font-size: 14px;
                }
                @if $type == pc{
                    margin-top: 40px;
                    font-size: 18px;
                }
            }
        }
        .img_wrap{
            @if $type == sp{
                margin-top: 50px;
            }
            @if $type == pc{
                width: 50%;
                padding-top: 20px;
            }
            .sliderArea{
                @if $type == sp{
                    display: flex;
                    align-items: center;
                }
                @if $type == pc{
                    display: flex;
                    align-items: center;
                }
            }
            .slider_thumb{
                @if $type == sp{
                    width: calc(100% - 60px);
                }
                @if $type == pc{
                    width: calc(100% - 80px);
                }
                .item{
                    @if $type == sp{
                    }
                    @if $type == pc{
                    }
                    img{
                        width: 100%;
                    }
                }
            }
            .thumb{
                @if $type == sp{
                    width: 60px;
                    padding-left: 20px;
                    text-align: center;
                    padding-right: 10px;
                }
                @if $type == pc{
                    width: 80px;
                    padding-left: 30px;
                    text-align: center;
                    
                }
                .item{
                    @if $type == sp{
                        margin: 5px 0;
                        img{
                            width: 100%;
                        }
                    }
                    @if $type == pc{
                        margin: 10px 0;
                        img{
                            width: 100%;
                        }
                        &:hover{
                            opacity: .7;
                        }
                    }
                }
                button{
                    margin: 0;
                    padding: 0;
                    background: none;
                    border: none;
                    border-radius: 0;
                    outline: none;
                    -webkit-appearance: none;
                    -moz-appearance: none;
                    appearance: none;
                }
                .slide-arrow{
                    cursor: pointer;
                }
                .next-arrow{
                    margin-top: 10px;
                }
            }
        }
    }
}//works
#recruit{
    position: relative;
    @if $type == sp{
        margin-top: 75px;
        &:before{
            content: "";
            display: inline-block;
            background: url(../images/home/recruit_kazari_sp.png) no-repeat;
            background-size: cover;
            width: 60px;
            height: 158px;
            position: absolute;
            top: -158px;
            left: 0px;
            mix-blend-mode: multiply;
        }
    }
    @if $type == pc{
        margin-top: 200px;
        &:before{
            content: "";
            display: inline-block;
            background: url(../images/home/recruit_kazari.png) no-repeat;
            width: 214px;
            height: 412px;
            position: absolute;
            top: -180px;
            left: 0px;
            mix-blend-mode: multiply;
            z-index: 2;
        }
    }
    > a{
        display: block;
        text-decoration: none;
        @if $type == pc{
            &:hover{
                .joinus span{
                    &:nth-of-type(1){
                        transform: translateY(100%);
                    }
                    &:nth-of-type(2){
                        transform: translateY(0);
                    }
                }
                figure{
                    &:before{
                        opacity: 1;
                    }
                }
            }
        }
        
        figure{
            @if $type == sp{
                background: url(../images/home/recruit_img_sp.png) no-repeat center;
                background-size: cover;
                width: 100%;
                height: 221px;
                img{
                    display: none;
                }
            }
            @if $type == pc{
                background: url(../images/home/recruit_img.png) no-repeat center;
                background-size: cover;
                width: 100%;
                height: 455px;
                &:before{
                    content: "";
                    display: block;
                    width: 100%;
                    height: 455px;
                    position: absolute;
                    background-color: rgba(0,0,0,0.5);
                    transition: .5s;
                    opacity: 0;
                    top: 0;
                    left: 0;
                }
                img{
                    position: relative;
                    z-index: 2;
                }
                .common_inner{
                    position: relative;
                    height: 100%;
                    display: flex;
                    align-items: center;
                }
            }
            .joinus{
                @if $type == sp{
                    display: none;
                }
                @if $type == pc{
                    position: absolute;
                    bottom: -53px;
                    right: 80px;
                    overflow: hidden;
                    backface-visibility: hidden;
                    span{
                        display: block;
                        transition: transform .6s cubic-bezier(.85,0,.15,1),-webkit-transform .6s cubic-bezier(.85,0,.15,1);
                        backface-visibility: hidden;
                        &:nth-of-type(2){
                            position: absolute;
                            bottom: 0;
                            left: 0;
                            -webkit-transform: translateY(-100%);
                            transform: translateY(-100%);
                        }
                    }
                }
            }
        }
    }
    p{
        font-weight: bold;
        @if $type == sp{
            font-size: 12px;
            margin-top: 10px;
        }
        @if $type == pc{
            font-size: 15px;
            margin-top: 20px;
            padding-left: 80px;
        }
    }
}//recruit

#contact{
    @if $type == sp{
        margin-top: 120px;
    }
    @if $type == pc{
        margin-top: 210px;
    }
    .common_inner{
        position: relative;
        @if $type == sp{
            border: 1px solid $c_gray;
            padding: 20px;
            width: calc(100% - 40px);
            margin: 0 auto;
        }
        @if $type == pc{
            border: 2px solid $c_gray;
            padding: 50px;
        }
        &:before{
            content: "";
            display: inline-block;
            background: url(../images/home/contact_shape.png) no-repeat;
            background-size: cover;
            position: absolute;
            @if $type == sp{
                width: 42px;
                height: 61px;
                top: -23px;
                left: calc(50% - 65px);
            }
            @if $type == pc{
                width: 84px;
                height: 122px;
                top: -66px;
                left: calc(50% - 100px);
            }
        }
    }
    h2{
        color: #434343;
        font-family: $ff_en;
        text-align: center;
        @if $type == sp{
            font-size: 18px;
        }
        @if $type == pc{
            font-size: 28px;
        }
    }
    .contact_wrap{
        @if $type == sp{
            margin-top: 20px;
        }
        @if $type == pc{
            display: flex;
            justify-content: space-between;
            margin-top: 40px;
        }
        .tel_wrap{
            background-color: #f4f3f5;
            text-align: center;
            @if $type == sp{
                padding: 20px;
            }
            @if $type == pc{
                width: calc(50% - 5px);
                padding: 50px;
            }
            .title{
                font-weight: bold;
                @if $type == sp{
                    font-size: 13px;
                }
                @if $type == pc{
                    font-size: 16px;
                }
            }
            a{
                color: #000;
                font-family: $ff_oswald;
                text-decoration: none;
                letter-spacing: 0.1em;
                font-weight: bold;
                display: inline-block;
                @if $type == sp{
                    font-size: 23px;
                    margin-top: 15px;
                }
                @if $type == pc{
                    font-size: 36px;
                    pointer-events: none;
                    margin-top: 30px;
                }
            }
            .sub{
                @if $type == sp{
                    font-size: 11px;
                    margin-top: 10px;
                }
                @if $type == pc{
                    font-size: 13px;
                    margin-top: 20px;
                }
            }
        }
        .form_wrap{
            background-color: #f4f3f5;
            text-align: center;
            @if $type == sp{
                margin-top: 10px;
                padding: 20px;
            }
            @if $type == pc{
                width: calc(50% - 5px);
                padding: 50px;
            }
            .title{
                font-weight: bold;
                @if $type == sp{
                    font-size: 13px;
                }
                @if $type == pc{
                    font-size: 16px;
                }
            }
            a{
                color: #fff;
                text-decoration: none;
                letter-spacing: 0.1em;
                display: inline-block;
                position: relative;
                @if $type == pc{
                    &:hover{
                        span{
                            background-color: #fff;
                            color: $c_base;
                        }
                    }
                    &:before{
                        content: "";
                        display: inline-block;
                        background: url(../images/common/botton_shadow.png) no-repeat;
                        width: 100%;
                        height: 65px;
                        position: absolute;
                        right: -12px;
                        bottom: -12px;
                    }
                }
                
                span{
                    display: block;
                    position: relative;
                    color: #fff;
                    background-color: $c_base;
                    border: 1px solid $c_base;
                    width: 100%;
                    height: 100%;
                    @if $type == sp{
                        font-size: 15px;
                        margin-top: 15px;
                        padding: 20px 10px;
                        z-index: 2;
                    }
                    @if $type == pc{
                        font-size: 18px;
                        margin-top: 30px;
                        padding: 25px 30px;
                        z-index: 2;
                        transition: .3s;
                    }
                }
            }
        }
    }
}//contact

#footer{
    @if $type == sp{
        margin-top: 60px;
    }
    @if $type == pc{
        margin-top: 180px;
    }
}

.sample{
    @if $type == sp{
    }
    @if $type == pc{
    }
}