@charset "UTF-8";
/* --------------- variables --------------------- */
/* Break Point */
/* Color */
/*サイトカラー*/
/*サイトカラー*/
/*テキストに使うフォントカラー*/
/*テキスト内で使うリンクカラー*/
/* font */
@import url("https://fonts.googleapis.com/css2?family=Lato:wght@400;700&family=Noto+Sans+JP:wght@400;700;900&family=Oswald:wght@400;700&display=swap");
@media screen and (max-width: 980px) {
  #contents {
    letter-spacing: 2px; }
  .common_inner {
    padding: 0 20px; }
  #main {
    overflow: hidden;
    background: url(../images/home/main_bg_sp.png) no-repeat center top;
    background-size: 100% auto;
    position: relative;
    padding-top: 350px;
    margin-top: -65px; }
    #main .catch {
      -ms-writing-mode: tb-rl;
      writing-mode: vertical-rl;
      color: #fff;
      position: absolute;
      top: 0;
      left: 0;
      width: 20px;
      height: 100%;
      background-color: #00a3a8;
      color: #fff;
      font-size: 11px;
      padding-top: 10px;
      letter-spacing: .1em;
      display: flex;
      align-items: center; }
    #main .mv_catch {
      font-size: 15px;
      font-weight: 900;
      position: relative;
      z-index: 2;
      padding-left: 40px;
      /*以下遅延の指定*/ }
      #main .mv_catch .c-text {
        display: inline-block;
        overflow: hidden; }
        #main .mv_catch .c-text:not(:first-of-type) {
          margin-top: 10px;
          margin-top: 5px; }
        #main .mv_catch .c-text span {
          background-color: #fff;
          display: inline-block;
          padding: 5px; }
      #main .mv_catch .slidein {
        transform: translateY(-100%);
        animation: slidein 0.8s ease forwards; }
  @keyframes slidein {
    100% {
      transform: translateY(0%); } }
      #main .mv_catch .txt01 {
        animation-delay: 1s; }
      #main .mv_catch .txt02 {
        animation-delay: 1.8s; }
      #main .mv_catch .txt03 {
        animation-delay: 2.6s; }
    #main .logo {
      margin-top: 15px;
      padding-left: 40px; }
      #main .logo img {
        width: 94px; }
    #main .mv_img {
      overflow: hidden;
      position: absolute;
      top: 70px;
      right: -70px;
      width: 375px;
      height: 375px; }
      #main .mv_img img {
        max-width: 100%; }
      #main .mv_img.fadein {
        opacity: 0;
        animation: fadein 2s ease forwards; }
  @keyframes fadein {
    100% {
      opacity: 1; } }
  #aspnote {
    margin-top: 35px; }
    #aspnote h2 img {
      width: 207px; }
    #aspnote .note_list {
      margin-top: 25px; }
  #online {
    text-align: center;
    margin-top: 60px; }
    #online img {
      max-width: 100%; }
    #online a {
      display: block;
      transition: 0.3s; }
      #online a:hover {
        opacity: .7; }
  #aboutus {
    margin-top: 70px; }
    #aboutus .heading {
      color: #3b3b3b;
      display: flex;
      align-items: center;
      position: relative;
      font-weight: bold;
      line-height: 1;
      padding-left: 25px; }
      #aboutus .heading:before {
        content: "";
        display: inline-block;
        background: url(../images/common/h2_kazari.png) no-repeat 0 0;
        background-size: cover;
        width: 26px;
        height: 26px;
        position: absolute;
        left: 0;
        top: -20px;
        width: 13px;
        height: 13px;
        top: -10px; }
      #aboutus .heading .en_title {
        font-family: "Lato", sans-serif;
        letter-spacing: 0.2em;
        font-size: 15px; }
      #aboutus .heading h2 {
        position: relative;
        font-size: 12px;
        padding-left: 60px;
        margin-left: 5px; }
        #aboutus .heading h2:before {
          content: "";
          display: inline-block;
          height: 1px;
          width: 70px;
          background-color: #3b3b3b;
          position: absolute;
          top: 10px;
          left: 0;
          width: 55px;
          top: 6px; }
    #aboutus #company {
      margin-top: 35px; }
      #aboutus #company figure img {
        max-width: 100%; }
      #aboutus #company .text_wrap {
        margin-top: 35px; }
        #aboutus #company .text_wrap h3 {
          font-size: 20px; }
        #aboutus #company .text_wrap p {
          line-height: 1.8;
          margin-top: 10px;
          font-size: 14px; }
        #aboutus #company .text_wrap .more_wrap {
          text-align: right; }
        #aboutus #company .text_wrap .more {
          text-decoration: none;
          color: #000;
          display: inline-block;
          position: relative;
          font-weight: bold;
          font-family: "Lato", sans-serif;
          margin-top: 15px;
          font-size: 14px;
          padding-left: 65px; }
          #aboutus #company .text_wrap .more:before {
            content: "";
            display: inline-block;
            border: 1px solid #000;
            border-radius: 50%;
            position: absolute;
            top: 0;
            left: 0;
            background: url(../images/common/arrow.png) no-repeat center;
            width: 45px;
            height: 45px;
            background-size: 8px; }
          #aboutus #company .text_wrap .more span {
            display: inline-block;
            background: url(../images/common/more_line.png) no-repeat left bottom;
            padding: 5px 0 20px; }
    #aboutus #printing {
      margin-top: 30px; }
      #aboutus #printing figure {
        transform: translateX(-20px); }
        #aboutus #printing figure img {
          width: calc(100% + 20px); }
      #aboutus #printing .text_wrap {
        margin-top: 35px; }
        #aboutus #printing .text_wrap h3 {
          font-size: 20px; }
        #aboutus #printing .text_wrap p {
          line-height: 1.8;
          margin-top: 10px;
          font-size: 14px; }
        #aboutus #printing .text_wrap .more_wrap {
          text-align: right; }
        #aboutus #printing .text_wrap .more {
          text-decoration: none;
          color: #000;
          display: inline-block;
          position: relative;
          font-weight: bold;
          font-family: "Lato", sans-serif;
          margin-top: 15px;
          font-size: 14px;
          padding-left: 65px; }
          #aboutus #printing .text_wrap .more:before {
            content: "";
            display: inline-block;
            border: 1px solid #000;
            border-radius: 50%;
            position: absolute;
            top: 0;
            left: 0;
            background: url(../images/common/arrow.png) no-repeat center;
            width: 45px;
            height: 45px;
            background-size: 8px; }
          #aboutus #printing .text_wrap .more span {
            display: inline-block;
            background: url(../images/common/more_line.png) no-repeat left bottom;
            padding: 5px 0 20px; }
    #aboutus #service {
      margin-top: 30px; }
      #aboutus #service figure img {
        width: calc(100% + 20px); }
      #aboutus #service .text_wrap {
        margin-top: 35px; }
        #aboutus #service .text_wrap h3 {
          font-size: 20px; }
        #aboutus #service .text_wrap p {
          line-height: 1.8;
          margin-top: 10px;
          font-size: 14px; }
        #aboutus #service .text_wrap .more_wrap {
          text-align: right; }
        #aboutus #service .text_wrap .more {
          text-decoration: none;
          color: #000;
          display: inline-block;
          position: relative;
          font-weight: bold;
          font-family: "Lato", sans-serif;
          margin-top: 15px;
          font-size: 14px;
          padding-left: 65px; }
          #aboutus #service .text_wrap .more:before {
            content: "";
            display: inline-block;
            border: 1px solid #000;
            border-radius: 50%;
            position: absolute;
            top: 0;
            left: 0;
            background: url(../images/common/arrow.png) no-repeat center;
            width: 45px;
            height: 45px;
            background-size: 8px; }
          #aboutus #service .text_wrap .more span {
            display: inline-block;
            background: url(../images/common/more_line.png) no-repeat left bottom;
            padding: 5px 0 20px; }
  #works {
    margin-top: 70px;
    position: relative; }
    #works:before {
      content: "";
      display: inline-block;
      background: url(../images/home/works_kazari_sp.png) no-repeat;
      background-size: cover;
      width: 227px;
      height: 301px;
      position: absolute;
      right: 0;
      bottom: 140px; }
    #works .heading {
      color: #3b3b3b;
      display: flex;
      align-items: center;
      position: relative;
      font-weight: bold;
      line-height: 1;
      padding-left: 25px; }
      #works .heading:before {
        content: "";
        display: inline-block;
        background: url(../images/common/h2_kazari.png) no-repeat 0 0;
        background-size: cover;
        width: 26px;
        height: 26px;
        position: absolute;
        left: 0;
        top: -20px;
        width: 13px;
        height: 13px;
        top: -10px; }
      #works .heading .en_title {
        font-family: "Lato", sans-serif;
        letter-spacing: 0.2em;
        font-size: 15px; }
      #works .heading h2 {
        position: relative;
        font-size: 12px;
        padding-left: 60px;
        margin-left: 5px; }
        #works .heading h2:before {
          content: "";
          display: inline-block;
          height: 1px;
          width: 70px;
          background-color: #3b3b3b;
          position: absolute;
          top: 10px;
          left: 0;
          width: 55px;
          top: 6px; }
    #works .works_wrap .more_wrap {
      text-align: right; }
    #works .works_wrap .more {
      text-decoration: none;
      color: #000;
      display: inline-block;
      position: relative;
      font-weight: bold;
      font-family: "Lato", sans-serif;
      margin-top: 30px;
      font-size: 14px;
      padding-left: 65px; }
      #works .works_wrap .more:before {
        content: "";
        display: inline-block;
        border: 1px solid #000;
        border-radius: 50%;
        position: absolute;
        top: 0;
        left: 0;
        background: url(../images/common/arrow.png) no-repeat center;
        width: 45px;
        height: 45px;
        background-size: 8px; }
      #works .works_wrap .more span {
        display: inline-block;
        background: url(../images/common/more_line.png) no-repeat left bottom;
        padding: 5px 0 20px; }
    #works .works_wrap .text_wrap {
      position: relative;
      margin-top: 35px; }
      #works .works_wrap .text_wrap:before {
        content: "";
        display: inline-block;
        background: url(../images/home/h3_works_.png) no-repeat;
        background-size: cover;
        position: absolute;
        width: 179px;
        height: 57px;
        top: -35px;
        right: 0px; }
      #works .works_wrap .text_wrap:after {
        content: "";
        display: inline-block;
        background: url(../images/home/works_shape.png) no-repeat;
        background-size: cover;
        position: absolute;
        width: 144px;
        height: 43px;
        right: 0;
        top: 140px;
        width: 72px;
        height: 22px;
        top: 15px;
        right: 60px; }
      #works .works_wrap .text_wrap h3 {
        border-left: 4px solid #00a3a8;
        line-height: 1.3;
        font-size: 20px;
        padding-left: 20px; }
      #works .works_wrap .text_wrap p {
        line-height: 1.8;
        margin-top: 20px;
        font-size: 14px; }
    #works .works_wrap .img_wrap {
      margin-top: 50px; }
      #works .works_wrap .img_wrap .sliderArea {
        display: flex;
        align-items: center; }
      #works .works_wrap .img_wrap .slider_thumb {
        width: calc(100% - 60px); }
        #works .works_wrap .img_wrap .slider_thumb .item img {
          width: 100%; }
      #works .works_wrap .img_wrap .thumb {
        width: 60px;
        padding-left: 20px;
        text-align: center;
        padding-right: 10px; }
        #works .works_wrap .img_wrap .thumb .item {
          margin: 5px 0; }
          #works .works_wrap .img_wrap .thumb .item img {
            width: 100%; }
        #works .works_wrap .img_wrap .thumb button {
          margin: 0;
          padding: 0;
          background: none;
          border: none;
          border-radius: 0;
          outline: none;
          -webkit-appearance: none;
          -moz-appearance: none;
          appearance: none; }
        #works .works_wrap .img_wrap .thumb .slide-arrow {
          cursor: pointer; }
        #works .works_wrap .img_wrap .thumb .next-arrow {
          margin-top: 10px; }
  #recruit {
    position: relative;
    margin-top: 75px; }
    #recruit:before {
      content: "";
      display: inline-block;
      background: url(../images/home/recruit_kazari_sp.png) no-repeat;
      background-size: cover;
      width: 60px;
      height: 158px;
      position: absolute;
      top: -158px;
      left: 0px;
      mix-blend-mode: multiply; }
    #recruit > a {
      display: block;
      text-decoration: none; }
      #recruit > a figure {
        background: url(../images/home/recruit_img_sp.png) no-repeat center;
        background-size: cover;
        width: 100%;
        height: 221px; }
        #recruit > a figure img {
          display: none; }
        #recruit > a figure .joinus {
          display: none; }
    #recruit p {
      font-weight: bold;
      font-size: 12px;
      margin-top: 10px; }
  #contact {
    margin-top: 120px; }
    #contact .common_inner {
      position: relative;
      border: 1px solid #868686;
      padding: 20px;
      width: calc(100% - 40px);
      margin: 0 auto; }
      #contact .common_inner:before {
        content: "";
        display: inline-block;
        background: url(../images/home/contact_shape.png) no-repeat;
        background-size: cover;
        position: absolute;
        width: 42px;
        height: 61px;
        top: -23px;
        left: calc(50% - 65px); }
    #contact h2 {
      color: #434343;
      font-family: "Lato", sans-serif;
      text-align: center;
      font-size: 18px; }
    #contact .contact_wrap {
      margin-top: 20px; }
      #contact .contact_wrap .tel_wrap {
        background-color: #f4f3f5;
        text-align: center;
        padding: 20px; }
        #contact .contact_wrap .tel_wrap .title {
          font-weight: bold;
          font-size: 13px; }
        #contact .contact_wrap .tel_wrap a {
          color: #000;
          font-family: "Oswald", sans-serif;
          text-decoration: none;
          letter-spacing: 0.1em;
          font-weight: bold;
          display: inline-block;
          font-size: 23px;
          margin-top: 15px; }
        #contact .contact_wrap .tel_wrap .sub {
          font-size: 11px;
          margin-top: 10px; }
      #contact .contact_wrap .form_wrap {
        background-color: #f4f3f5;
        text-align: center;
        margin-top: 10px;
        padding: 20px; }
        #contact .contact_wrap .form_wrap .title {
          font-weight: bold;
          font-size: 13px; }
        #contact .contact_wrap .form_wrap a {
          color: #fff;
          text-decoration: none;
          letter-spacing: 0.1em;
          display: inline-block;
          position: relative; }
          #contact .contact_wrap .form_wrap a span {
            display: block;
            position: relative;
            color: #fff;
            background-color: #00a3a8;
            border: 1px solid #00a3a8;
            width: 100%;
            height: 100%;
            font-size: 15px;
            margin-top: 15px;
            padding: 20px 10px;
            z-index: 2; }
  #footer {
    margin-top: 60px; } }

@media screen and (min-width: 980px) {
  #contents {
    letter-spacing: 2px; }
  #main {
    overflow: hidden; }
    #main .catch {
      -ms-writing-mode: tb-rl;
      writing-mode: vertical-rl;
      color: #fff; }
    #main .mv_catch {
      /*以下遅延の指定*/ }
      #main .mv_catch .c-text {
        display: inline-block;
        overflow: hidden; }
        #main .mv_catch .c-text:not(:first-of-type) {
          margin-top: 10px; }
        #main .mv_catch .c-text span {
          background-color: #fff;
          display: inline-block;
          padding: 5px; }
      #main .mv_catch .slidein {
        transform: translateY(-100%);
        animation: slidein 0.8s ease forwards; }
  @keyframes slidein {
    100% {
      transform: translateY(0%); } }
      #main .mv_catch .txt01 {
        animation-delay: 1s; }
      #main .mv_catch .txt02 {
        animation-delay: 1.8s; }
      #main .mv_catch .txt03 {
        animation-delay: 2.6s; }
    #main .mv_img {
      overflow: hidden; }
      #main .mv_img.fadein {
        opacity: 0;
        animation: fadein 2s ease forwards; }
  @keyframes fadein {
    100% {
      opacity: 1; } }
  #online {
    text-align: center; }
    #online img {
      max-width: 100%; }
    #online a {
      display: block;
      transition: 0.3s; }
      #online a:hover {
        opacity: .7; }
  #aboutus .heading {
    color: #3b3b3b;
    display: flex;
    align-items: center;
    position: relative;
    font-weight: bold;
    line-height: 1; }
    #aboutus .heading:before {
      content: "";
      display: inline-block;
      background: url(../images/common/h2_kazari.png) no-repeat 0 0;
      background-size: cover;
      width: 26px;
      height: 26px;
      position: absolute;
      left: 0;
      top: -20px; }
    #aboutus .heading .en_title {
      font-family: "Lato", sans-serif;
      letter-spacing: 0.2em; }
    #aboutus .heading h2 {
      position: relative; }
      #aboutus .heading h2:before {
        content: "";
        display: inline-block;
        height: 1px;
        width: 70px;
        background-color: #3b3b3b;
        position: absolute;
        top: 10px;
        left: 0; }
  #aboutus #company figure img {
    max-width: 100%; }
  #aboutus #company .text_wrap p {
    line-height: 1.8; }
  #aboutus #company .text_wrap .more {
    text-decoration: none;
    color: #000;
    display: inline-block;
    position: relative;
    font-weight: bold;
    font-family: "Lato", sans-serif; }
    #aboutus #company .text_wrap .more:before {
      content: "";
      display: inline-block;
      border: 1px solid #000;
      border-radius: 50%;
      position: absolute;
      top: 0;
      left: 0;
      background: url(../images/common/arrow.png) no-repeat center; }
    #aboutus #company .text_wrap .more span {
      display: inline-block;
      background: url(../images/common/more_line.png) no-repeat left bottom; }
  #aboutus #printing .text_wrap p {
    line-height: 1.8; }
  #aboutus #printing .text_wrap .more {
    text-decoration: none;
    color: #000;
    display: inline-block;
    position: relative;
    font-weight: bold;
    font-family: "Lato", sans-serif; }
    #aboutus #printing .text_wrap .more:before {
      content: "";
      display: inline-block;
      border: 1px solid #000;
      border-radius: 50%;
      position: absolute;
      top: 0;
      left: 0;
      background: url(../images/common/arrow.png) no-repeat center; }
    #aboutus #printing .text_wrap .more span {
      display: inline-block;
      background: url(../images/common/more_line.png) no-repeat left bottom; }
  #aboutus #service .text_wrap p {
    line-height: 1.8; }
  #aboutus #service .text_wrap .more {
    text-decoration: none;
    color: #000;
    display: inline-block;
    position: relative;
    font-weight: bold;
    font-family: "Lato", sans-serif; }
    #aboutus #service .text_wrap .more:before {
      content: "";
      display: inline-block;
      border: 1px solid #000;
      border-radius: 50%;
      position: absolute;
      top: 0;
      left: 0;
      background: url(../images/common/arrow.png) no-repeat center; }
    #aboutus #service .text_wrap .more span {
      display: inline-block;
      background: url(../images/common/more_line.png) no-repeat left bottom; }
  #works .heading {
    color: #3b3b3b;
    display: flex;
    align-items: center;
    position: relative;
    font-weight: bold;
    line-height: 1; }
    #works .heading:before {
      content: "";
      display: inline-block;
      background: url(../images/common/h2_kazari.png) no-repeat 0 0;
      background-size: cover;
      width: 26px;
      height: 26px;
      position: absolute;
      left: 0;
      top: -20px; }
    #works .heading .en_title {
      font-family: "Lato", sans-serif;
      letter-spacing: 0.2em; }
    #works .heading h2 {
      position: relative; }
      #works .heading h2:before {
        content: "";
        display: inline-block;
        height: 1px;
        width: 70px;
        background-color: #3b3b3b;
        position: absolute;
        top: 10px;
        left: 0; }
  #works .works_wrap .more_wrap {
    text-align: right; }
  #works .works_wrap .more {
    text-decoration: none;
    color: #000;
    display: inline-block;
    position: relative;
    font-weight: bold;
    font-family: "Lato", sans-serif; }
    #works .works_wrap .more:before {
      content: "";
      display: inline-block;
      border: 1px solid #000;
      border-radius: 50%;
      position: absolute;
      top: 0;
      left: 0;
      background: url(../images/common/arrow.png) no-repeat center; }
    #works .works_wrap .more span {
      display: inline-block;
      background: url(../images/common/more_line.png) no-repeat left bottom; }
  #works .works_wrap .text_wrap {
    position: relative; }
    #works .works_wrap .text_wrap:before {
      content: "";
      display: inline-block;
      background: url(../images/home/h3_works_.png) no-repeat;
      background-size: cover;
      position: absolute; }
    #works .works_wrap .text_wrap:after {
      content: "";
      display: inline-block;
      background: url(../images/home/works_shape.png) no-repeat;
      background-size: cover;
      position: absolute;
      width: 144px;
      height: 43px;
      right: 0;
      top: 140px; }
    #works .works_wrap .text_wrap h3 {
      border-left: 4px solid #00a3a8;
      line-height: 1.3; }
    #works .works_wrap .text_wrap p {
      line-height: 1.8; }
  #works .works_wrap .img_wrap .slider_thumb .item img {
    width: 100%; }
  #works .works_wrap .img_wrap .thumb button {
    margin: 0;
    padding: 0;
    background: none;
    border: none;
    border-radius: 0;
    outline: none;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none; }
  #works .works_wrap .img_wrap .thumb .slide-arrow {
    cursor: pointer; }
  #works .works_wrap .img_wrap .thumb .next-arrow {
    margin-top: 10px; }
  #recruit {
    position: relative; }
    #recruit > a {
      display: block;
      text-decoration: none; }
    #recruit p {
      font-weight: bold; }
  #contact .common_inner {
    position: relative; }
    #contact .common_inner:before {
      content: "";
      display: inline-block;
      background: url(../images/home/contact_shape.png) no-repeat;
      background-size: cover;
      position: absolute; }
  #contact h2 {
    color: #434343;
    font-family: "Lato", sans-serif;
    text-align: center; }
  #contact .contact_wrap .tel_wrap {
    background-color: #f4f3f5;
    text-align: center; }
    #contact .contact_wrap .tel_wrap .title {
      font-weight: bold; }
    #contact .contact_wrap .tel_wrap a {
      color: #000;
      font-family: "Oswald", sans-serif;
      text-decoration: none;
      letter-spacing: 0.1em;
      font-weight: bold;
      display: inline-block; }
  #contact .contact_wrap .form_wrap {
    background-color: #f4f3f5;
    text-align: center; }
    #contact .contact_wrap .form_wrap .title {
      font-weight: bold; }
    #contact .contact_wrap .form_wrap a {
      color: #fff;
      text-decoration: none;
      letter-spacing: 0.1em;
      display: inline-block;
      position: relative; }
      #contact .contact_wrap .form_wrap a span {
        display: block;
        position: relative;
        color: #fff;
        background-color: #00a3a8;
        border: 1px solid #00a3a8;
        width: 100%;
        height: 100%; } }

@media print, screen and (min-width: 981px) {
  #contents {
    letter-spacing: 2px; }
  .common_inner {
    width: 96%;
    max-width: 1260px;
    margin: 0 auto;
    padding: 0 60px 0 0; }
  #main {
    overflow: hidden;
    background: url(../images/home/main_bg.png) no-repeat center top;
    background-size: 100% auto;
    padding-top: 170px;
    margin-top: -170px;
    padding-bottom: 170px;
    position: relative; }
    #main .catch {
      -ms-writing-mode: tb-rl;
      writing-mode: vertical-rl;
      color: #fff;
      font-size: 18px;
      position: absolute;
      top: 90px;
      left: 60px; }
      #main .catch span {
        display: inline-block;
        padding: 7px 5px;
        background-color: #00a3a8;
        margin-left: 5px;
        letter-spacing: 0.1em; }
    #main .mv_catch {
      font-size: 2.1vw;
      font-weight: 900;
      position: relative;
      z-index: 2;
      margin-top: 250px;
      /*以下遅延の指定*/ }
      #main .mv_catch .c-text {
        display: inline-block;
        overflow: hidden; }
        #main .mv_catch .c-text:not(:first-of-type) {
          margin-top: 10px; }
        #main .mv_catch .c-text span {
          background-color: #fff;
          display: inline-block;
          padding: 5px; }
      #main .mv_catch .slidein {
        transform: translateY(-100%);
        animation: slidein 0.8s ease forwards; }
  @keyframes slidein {
    100% {
      transform: translateY(0%); } }
      #main .mv_catch .txt01 {
        animation-delay: 1s; }
      #main .mv_catch .txt02 {
        animation-delay: 1.8s; }
      #main .mv_catch .txt03 {
        animation-delay: 2.6s; }
    #main .logo {
      margin-top: 100px;
      padding-right: 20px; }
    #main .mv_img {
      overflow: hidden;
      display: inline-block;
      position: absolute;
      right: calc((100% - 1200px) / 2);
      top: 150px;
      width: 835px;
      height: 836px; }
      #main .mv_img img {
        max-width: 100%; }
      #main .mv_img.fadein {
        opacity: 0;
        animation: fadein 2s ease forwards; }
  @keyframes fadein {
    100% {
      opacity: 1; } }
  #aspnote {
    margin-top: 120px; }
    #aspnote .common_inner {
      display: flex; }
    #aspnote h2 {
      width: 278px; }
    #aspnote .note_list {
      width: calc(100% - 278px);
      padding-left: 50px; }
  #online {
    text-align: center;
    margin-top: 210px; }
    #online img {
      max-width: 100%; }
    #online a {
      display: block;
      transition: 0.3s; }
      #online a:hover {
        opacity: .7; }
  #aboutus {
    margin-top: 170px;
    position: relative; }
    #aboutus:before {
      content: "";
      display: inline-block;
      background: url(../images/home/aboutus_kazari_1.png) no-repeat;
      width: 171px;
      height: 412px;
      position: absolute;
      top: -290px;
      left: 0;
      z-index: -1; }
    #aboutus:after {
      content: "";
      display: inline-block;
      background: url(../images/home/aboutus_kazari_2.png) no-repeat;
      width: 749px;
      height: 300px;
      position: absolute;
      right: 0px;
      bottom: 290px; }
    #aboutus .heading {
      color: #3b3b3b;
      display: flex;
      align-items: center;
      position: relative;
      font-weight: bold;
      line-height: 1;
      padding-left: 35px; }
      #aboutus .heading:before {
        content: "";
        display: inline-block;
        background: url(../images/common/h2_kazari.png) no-repeat 0 0;
        background-size: cover;
        width: 26px;
        height: 26px;
        position: absolute;
        left: 0;
        top: -20px; }
      #aboutus .heading .en_title {
        font-family: "Lato", sans-serif;
        letter-spacing: 0.2em;
        font-size: 26px; }
      #aboutus .heading h2 {
        position: relative;
        font-size: 18px;
        padding-left: 75px;
        margin-left: 5px; }
        #aboutus .heading h2:before {
          content: "";
          display: inline-block;
          height: 1px;
          width: 70px;
          background-color: #3b3b3b;
          position: absolute;
          top: 10px;
          left: 0; }
    #aboutus #company {
      padding-top: 100px;
      display: flex; }
      #aboutus #company figure {
        width: calc(100% - 490px); }
        #aboutus #company figure img {
          max-width: 100%; }
      #aboutus #company .text_wrap {
        width: 490px;
        padding-left: 70px;
        padding-top: 230px;
        position: relative; }
        #aboutus #company .text_wrap:before {
          content: "";
          display: inline-block;
          background: url(../images/home/company_img_2.png) no-repeat;
          width: 343px;
          height: 222px;
          position: absolute;
          right: 0;
          top: -80px; }
        #aboutus #company .text_wrap h3 {
          font-size: 28px; }
        #aboutus #company .text_wrap p {
          line-height: 1.8;
          margin-top: 20px;
          font-size: 18px; }
        #aboutus #company .text_wrap .more {
          text-decoration: none;
          color: #000;
          display: inline-block;
          position: relative;
          font-weight: bold;
          font-family: "Lato", sans-serif;
          margin-top: 60px;
          font-size: 19px;
          padding-left: 90px; }
          #aboutus #company .text_wrap .more:hover:before {
            background: url(../images/common/arrow_w.png) no-repeat center #00a3a8;
            border: 1px solid #00a3a8; }
          #aboutus #company .text_wrap .more:before {
            content: "";
            display: inline-block;
            border: 1px solid #000;
            border-radius: 50%;
            position: absolute;
            top: 0;
            left: 0;
            background: url(../images/common/arrow.png) no-repeat center;
            width: 70px;
            height: 70px;
            transition: .3s; }
          #aboutus #company .text_wrap .more span {
            display: inline-block;
            background: url(../images/common/more_line.png) no-repeat left bottom;
            padding: 15px 0 30px; }
    #aboutus #printing {
      padding-top: 140px;
      display: flex;
      flex-direction: row-reverse; }
      #aboutus #printing figure {
        width: calc(100% - 490px); }
        #aboutus #printing figure img {
          max-width: 100%; }
      #aboutus #printing .text_wrap {
        width: 490px;
        padding-right: 70px;
        padding-top: 230px;
        position: relative; }
        #aboutus #printing .text_wrap:before {
          content: "";
          display: inline-block;
          background: url(../images/home/printing_img_2.png) no-repeat;
          width: 172px;
          height: 121px;
          position: absolute;
          right: 55px;
          top: 30px; }
        #aboutus #printing .text_wrap h3 {
          font-size: 28px; }
        #aboutus #printing .text_wrap p {
          line-height: 1.8;
          margin-top: 20px;
          font-size: 18px; }
        #aboutus #printing .text_wrap .more {
          text-decoration: none;
          color: #000;
          display: inline-block;
          position: relative;
          font-weight: bold;
          font-family: "Lato", sans-serif;
          margin-top: 60px;
          font-size: 19px;
          padding-left: 90px; }
          #aboutus #printing .text_wrap .more:hover:before {
            background: url(../images/common/arrow_w.png) no-repeat center #00a3a8;
            border: 1px solid #00a3a8; }
          #aboutus #printing .text_wrap .more:before {
            content: "";
            display: inline-block;
            border: 1px solid #000;
            border-radius: 50%;
            position: absolute;
            top: 0;
            left: 0;
            background: url(../images/common/arrow.png) no-repeat center;
            width: 70px;
            height: 70px;
            transition: .3s; }
          #aboutus #printing .text_wrap .more span {
            display: inline-block;
            background: url(../images/common/more_line.png) no-repeat left bottom;
            padding: 15px 0 30px; }
    #aboutus #service {
      padding-top: 100px;
      display: flex; }
      #aboutus #service figure {
        width: calc(100% - 435px); }
        #aboutus #service figure img {
          max-width: 100%; }
      #aboutus #service .text_wrap {
        width: 435px;
        padding-left: 120px;
        padding-top: 150px; }
        #aboutus #service .text_wrap h3 {
          font-size: 28px; }
        #aboutus #service .text_wrap p {
          line-height: 1.8;
          margin-top: 20px;
          font-size: 18px; }
        #aboutus #service .text_wrap .more {
          text-decoration: none;
          color: #000;
          display: inline-block;
          position: relative;
          font-weight: bold;
          font-family: "Lato", sans-serif;
          margin-top: 60px;
          font-size: 19px;
          padding-left: 90px; }
          #aboutus #service .text_wrap .more:hover:before {
            background: url(../images/common/arrow_w.png) no-repeat center #00a3a8;
            border: 1px solid #00a3a8; }
          #aboutus #service .text_wrap .more:before {
            content: "";
            display: inline-block;
            border: 1px solid #000;
            border-radius: 50%;
            position: absolute;
            top: 0;
            left: 0;
            background: url(../images/common/arrow.png) no-repeat center;
            width: 70px;
            height: 70px;
            transition: .3s; }
          #aboutus #service .text_wrap .more span {
            display: inline-block;
            background: url(../images/common/more_line.png) no-repeat left bottom;
            padding: 15px 0 30px; }
  #works {
    margin-top: 200px;
    position: relative; }
    #works:before {
      content: "";
      display: inline-block;
      background: url(../images/home/works_kazari.png) no-repeat;
      width: 751px;
      height: 602px;
      position: absolute;
      right: 0;
      top: 0; }
    #works .heading {
      color: #3b3b3b;
      display: flex;
      align-items: center;
      position: relative;
      font-weight: bold;
      line-height: 1;
      padding-left: 35px; }
      #works .heading:before {
        content: "";
        display: inline-block;
        background: url(../images/common/h2_kazari.png) no-repeat 0 0;
        background-size: cover;
        width: 26px;
        height: 26px;
        position: absolute;
        left: 0;
        top: -20px; }
      #works .heading .en_title {
        font-family: "Lato", sans-serif;
        letter-spacing: 0.2em;
        font-size: 26px; }
      #works .heading h2 {
        position: relative;
        font-size: 18px;
        padding-left: 75px;
        margin-left: 5px; }
        #works .heading h2:before {
          content: "";
          display: inline-block;
          height: 1px;
          width: 70px;
          background-color: #3b3b3b;
          position: absolute;
          top: 10px;
          left: 0; }
    #works .works_wrap {
      display: flex; }
      #works .works_wrap .more_wrap {
        text-align: right; }
      #works .works_wrap .more {
        text-decoration: none;
        color: #000;
        display: inline-block;
        position: relative;
        font-weight: bold;
        font-family: "Lato", sans-serif;
        margin-top: 60px;
        font-size: 19px;
        padding-left: 90px; }
        #works .works_wrap .more:hover:before {
          background: url(../images/common/arrow_w.png) no-repeat center #00a3a8;
          border: 1px solid #00a3a8; }
        #works .works_wrap .more:before {
          content: "";
          display: inline-block;
          border: 1px solid #000;
          border-radius: 50%;
          position: absolute;
          top: 0;
          left: 0;
          background: url(../images/common/arrow.png) no-repeat center;
          width: 70px;
          height: 70px;
          transition: .3s; }
        #works .works_wrap .more span {
          display: inline-block;
          background: url(../images/common/more_line.png) no-repeat left bottom;
          padding: 15px 0 30px; }
      #works .works_wrap .text_wrap {
        position: relative;
        width: 50%;
        padding-left: 80px;
        padding-top: 160px;
        padding-right: 130px; }
        #works .works_wrap .text_wrap:before {
          content: "";
          display: inline-block;
          background: url(../images/home/h3_works_.png) no-repeat;
          background-size: cover;
          position: absolute;
          width: 357px;
          height: 114px;
          top: 50px;
          left: 80px; }
        #works .works_wrap .text_wrap:after {
          content: "";
          display: inline-block;
          background: url(../images/home/works_shape.png) no-repeat;
          background-size: cover;
          position: absolute;
          width: 144px;
          height: 43px;
          right: 0;
          top: 140px; }
        #works .works_wrap .text_wrap h3 {
          border-left: 4px solid #00a3a8;
          line-height: 1.3;
          font-size: 30px;
          padding-left: 30px; }
        #works .works_wrap .text_wrap p {
          line-height: 1.8;
          margin-top: 40px;
          font-size: 18px; }
      #works .works_wrap .img_wrap {
        width: 50%;
        padding-top: 20px; }
        #works .works_wrap .img_wrap .sliderArea {
          display: flex;
          align-items: center; }
        #works .works_wrap .img_wrap .slider_thumb {
          width: calc(100% - 80px); }
          #works .works_wrap .img_wrap .slider_thumb .item img {
            width: 100%; }
        #works .works_wrap .img_wrap .thumb {
          width: 80px;
          padding-left: 30px;
          text-align: center; }
          #works .works_wrap .img_wrap .thumb .item {
            margin: 10px 0; }
            #works .works_wrap .img_wrap .thumb .item img {
              width: 100%; }
            #works .works_wrap .img_wrap .thumb .item:hover {
              opacity: .7; }
          #works .works_wrap .img_wrap .thumb button {
            margin: 0;
            padding: 0;
            background: none;
            border: none;
            border-radius: 0;
            outline: none;
            -webkit-appearance: none;
            -moz-appearance: none;
            appearance: none; }
          #works .works_wrap .img_wrap .thumb .slide-arrow {
            cursor: pointer; }
          #works .works_wrap .img_wrap .thumb .next-arrow {
            margin-top: 10px; }
  #recruit {
    position: relative;
    margin-top: 200px; }
    #recruit:before {
      content: "";
      display: inline-block;
      background: url(../images/home/recruit_kazari.png) no-repeat;
      width: 214px;
      height: 412px;
      position: absolute;
      top: -180px;
      left: 0px;
      mix-blend-mode: multiply;
      z-index: 2; }
    #recruit > a {
      display: block;
      text-decoration: none; }
      #recruit > a:hover .joinus span:nth-of-type(1) {
        transform: translateY(100%); }
      #recruit > a:hover .joinus span:nth-of-type(2) {
        transform: translateY(0); }
      #recruit > a:hover figure:before {
        opacity: 1; }
      #recruit > a figure {
        background: url(../images/home/recruit_img.png) no-repeat center;
        background-size: cover;
        width: 100%;
        height: 455px; }
        #recruit > a figure:before {
          content: "";
          display: block;
          width: 100%;
          height: 455px;
          position: absolute;
          background-color: rgba(0, 0, 0, 0.5);
          transition: .5s;
          opacity: 0;
          top: 0;
          left: 0; }
        #recruit > a figure img {
          position: relative;
          z-index: 2; }
        #recruit > a figure .common_inner {
          position: relative;
          height: 100%;
          display: flex;
          align-items: center; }
        #recruit > a figure .joinus {
          position: absolute;
          bottom: -53px;
          right: 80px;
          overflow: hidden;
          backface-visibility: hidden; }
          #recruit > a figure .joinus span {
            display: block;
            transition: transform 0.6s cubic-bezier(0.85, 0, 0.15, 1), -webkit-transform 0.6s cubic-bezier(0.85, 0, 0.15, 1);
            backface-visibility: hidden; }
            #recruit > a figure .joinus span:nth-of-type(2) {
              position: absolute;
              bottom: 0;
              left: 0;
              -webkit-transform: translateY(-100%);
              transform: translateY(-100%); }
    #recruit p {
      font-weight: bold;
      font-size: 15px;
      margin-top: 20px;
      padding-left: 80px; }
  #contact {
    margin-top: 210px; }
    #contact .common_inner {
      position: relative;
      border: 2px solid #868686;
      padding: 50px; }
      #contact .common_inner:before {
        content: "";
        display: inline-block;
        background: url(../images/home/contact_shape.png) no-repeat;
        background-size: cover;
        position: absolute;
        width: 84px;
        height: 122px;
        top: -66px;
        left: calc(50% - 100px); }
    #contact h2 {
      color: #434343;
      font-family: "Lato", sans-serif;
      text-align: center;
      font-size: 28px; }
    #contact .contact_wrap {
      display: flex;
      justify-content: space-between;
      margin-top: 40px; }
      #contact .contact_wrap .tel_wrap {
        background-color: #f4f3f5;
        text-align: center;
        width: calc(50% - 5px);
        padding: 50px; }
        #contact .contact_wrap .tel_wrap .title {
          font-weight: bold;
          font-size: 16px; }
        #contact .contact_wrap .tel_wrap a {
          color: #000;
          font-family: "Oswald", sans-serif;
          text-decoration: none;
          letter-spacing: 0.1em;
          font-weight: bold;
          display: inline-block;
          font-size: 36px;
          pointer-events: none;
          margin-top: 30px; }
        #contact .contact_wrap .tel_wrap .sub {
          font-size: 13px;
          margin-top: 20px; }
      #contact .contact_wrap .form_wrap {
        background-color: #f4f3f5;
        text-align: center;
        width: calc(50% - 5px);
        padding: 50px; }
        #contact .contact_wrap .form_wrap .title {
          font-weight: bold;
          font-size: 16px; }
        #contact .contact_wrap .form_wrap a {
          color: #fff;
          text-decoration: none;
          letter-spacing: 0.1em;
          display: inline-block;
          position: relative; }
          #contact .contact_wrap .form_wrap a:hover span {
            background-color: #fff;
            color: #00a3a8; }
          #contact .contact_wrap .form_wrap a:before {
            content: "";
            display: inline-block;
            background: url(../images/common/botton_shadow.png) no-repeat;
            width: 100%;
            height: 65px;
            position: absolute;
            right: -12px;
            bottom: -12px; }
          #contact .contact_wrap .form_wrap a span {
            display: block;
            position: relative;
            color: #fff;
            background-color: #00a3a8;
            border: 1px solid #00a3a8;
            width: 100%;
            height: 100%;
            font-size: 18px;
            margin-top: 30px;
            padding: 25px 30px;
            z-index: 2;
            transition: .3s; }
  #footer {
    margin-top: 180px; } }
